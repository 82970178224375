<template>
  <page-layout v-loading="list.loading">
    <page-header :breadcrumb="['机构开通', '行业模板']" />

    <page-main>
      <el-table ref="table" :data="list.items">
        <el-table-column label="行业" prop="name" />
        <el-table-column label="阅读本登录" prop="login_type">
          <template v-slot="{ row }">
            <template v-for="it in LibLoginType">
              <el-tag
                :key="it.value"
                v-if="row.login_type?.includes(it.value)"
                size="small"
                disable-transitions
                style="margin-right: 1em"
              >
                {{ it.label }}
              </el-tag>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="应用" prop="applications">
          <template v-slot="{ row }">
            <div class="tag-list">
              <template v-for="it in LibAppType">
                <el-tag
                  :key="it.value"
                  v-if="row.applications?.includes(it.value)"
                  size="small"
                  type="success"
                  disable-transitions
                  style="margin-right: 1em"
                >
                  {{ it.label }}
                </el-tag>
              </template>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100px">
          <template slot-scope="{ row }">
            <el-button type="text" @click="edit.onEdit(row)"> 编辑 </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-main>

    <edit />
  </page-layout>
</template>

<script lang="ts" setup>
import { LibAppType, LibLoginType } from '@/consts'
import _ from 'lodash'
import { reactive } from 'vue'
import Edit from './edit.vue'
import { store } from './store'

const include = _.includes

const state = reactive(store)
state.list.fetch()

const { list, edit } = state
</script>

<style scoped>
.tag {
  margin-right: 6px;
  margin-bottom: 6px;
}
</style>
