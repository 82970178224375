import AppStore from '@/store'
import AdminAccount from '@/views/admin-account/index.vue'
import Feedback from '@/views/feedback/feedback.component.vue'
import LibTemp from '@/views/lib-template/index.vue'
import Lib from '@/views/lib/lib.component.vue'
import MaintainAccount from '@/views/maintain_account/maintain-account.component.vue'
import OptsReport from '@/views/reports/opts-report/opts-report.component.vue'
import Role from '@/views/role/index.vue'
import ThirdPartyApp from '@/views/third-party-app/index.vue'
import _ from 'lodash'
import { RouteConfigSingleView } from 'vue-router/types/router'

const Tag = () =>
  import(/* webpackChunkName: "res" */ '@/views/tag/tag.component.vue')
const Book = () =>
  import(/* webpackChunkName: "res" */ '@/views/book/book.component.vue')
const TopicTransform = () =>
  import(
    /* webpackChunkName: "res" */ '@/views/topic-transform/topic-transform.component.vue'
  )

const HomeSection = () =>
  import(
    /* webpackChunkName: "res" */ '@/views/home-section/home-section.component.vue'
  )
const Topic = () =>
  import(/* webpackChunkName: "res" */ '@/views/topic/topic.component.vue')
const TopicTag = () =>
  import(
    /* webpackChunkName: "res" */ '@/views/topic-tag/topic-tag.component.vue'
  )
const DailyBook = () =>
  import(
    /* webpackChunkName: "res" */ '@/views/daily-book/daily-book.component.vue'
  )
const BookRecommend = () =>
  import(
    /* webpackChunkName: "res" */ '@/views/book-recommend/book-recommend.component.vue'
  )
const MagazineCate = () =>
  import(/* webpackChunkName: "res" */ '@/views/magazine-cate/index.vue')
const MagazineCateList = () =>
  import(/* webpackChunkName: "res" */ '@/views/magazine-cate-list/index.vue')
const MagazineRecommend = () =>
  import(/* webpackChunkName: "res" */ '@/views/magazine-recommend/index.vue')

const ReaderDevice = () =>
  import(
    /* webpackChunkName: "device" */ '@/views/reader-device/reader-device.component.vue'
  )
const ReaderDeviceAuthSetting = () =>
  import(
    /* webpackChunkName: "device" */ '@/views/reader-device-auth-setting/reader-device.component.vue'
  )
const ReaderDeviceAuthList = () =>
  import(
    /* webpackChunkName: "device" */ '@/views/reader-device-auth-list/reader-device.component.vue'
  )

const OTA = () =>
  import(/* webpackChunkName: 'device' */ '@/views/ota/ota.component.vue')
const OTAHelper = () =>
  import(/* webpackChunkName: 'device' */ '@/views/ota-helper/index.vue')
const DeviceChangeLibLog = () =>
  import(
    /* webpackChunkName: 'device' */ '@/views/device-change-lib-log/index.vue'
  )

const Order = () =>
  import(/* webpackChunkName: "orders" */ '@/views/order/order.component.vue')

interface Config extends RouteConfigSingleView {
  meta: {
    title: string
    icon?: string
  }
  children?: Config[]
}

const RouterView = {
  template: '<router-view />'
}

const asyncRouterConfig: Config[] = [
  {
    path: 'g-report',
    component: RouterView,
    meta: {
      title: '数据统计',
      icon: 'el-icon-ant-dashboard'
    },
    children: [
      {
        path: 'ops-report',
        name: 'optsReport',
        component: OptsReport,
        meta: { title: '运营数据统计' }
      }
    ]
  },
  {
    path: 'g-res',
    component: RouterView,
    meta: {
      title: '电子书',
      icon: 'el-icon-document'
    },
    children: [
      {
        path: 'tag',
        name: 'tag',
        component: Tag,
        meta: { title: '系统分类' }
      },
      {
        path: 'book',
        name: 'book',
        component: Book,
        meta: { title: '图书管理' }
      },
      {
        path: 'topic-transform',
        name: 'topicTransform',
        component: TopicTransform,
        meta: { title: '专题转epub' }
      }
    ]
  },
  {
    path: 'g-topic',
    component: RouterView,
    meta: {
      title: '书单',
      icon: 'el-icon-star-off'
    },
    children: [
      {
        path: 'topic-tag',
        name: 'topicTag',
        component: TopicTag,
        meta: { title: '书单栏目' }
      },
      {
        path: 'topic',
        name: 'topic',
        component: Topic,
        meta: { title: '书单列表' }
      }
    ]
  },

  {
    path: 'g-magazine',
    component: RouterView,
    meta: {
      title: '期刊',
      icon: 'el-icon-notebook-1'
    },
    children: [
      {
        path: 'magazine-cate',
        name: 'magazineCate',
        component: MagazineCate,
        meta: { title: '期刊分类' }
      },
      {
        path: 'magazine-cate-list',
        name: 'magazineCateList',
        component: MagazineCateList,
        meta: { title: '期刊目录' }
      },
      {
        path: 'magazine-recommend',
        name: 'magazineRecommend',
        component: MagazineRecommend,
        meta: { title: '推荐期刊' }
      }
    ]
  },

  {
    path: 'g-package',
    component: RouterView,
    meta: {
      title: '内容运营',
      icon: 'el-icon-share'
    },
    children: [
      {
        path: 'home-section',
        name: 'homeSection',
        component: HomeSection,
        meta: { title: '行业书城配置' }
      },
      {
        path: 'daily-book',
        name: 'dailyBook',
        component: DailyBook,
        meta: { title: '天天新书' }
      },
      {
        path: 'book-recommend',
        name: 'bookRecommend',
        component: BookRecommend,
        meta: { title: '推荐阅读' }
      }
    ]
  },
  {
    path: 'g-reader',
    component: RouterView,
    meta: {
      title: '阅读本',
      icon: 'el-icon-mobile-phone'
    },
    children: [
      {
        path: 'reader-device',
        name: 'readerDevice',
        component: ReaderDevice,
        meta: { title: '设备管理' }
      },
      {
        path: 'ota',
        name: 'ota',
        component: OTA,
        meta: { title: 'OTA更新' }
      },
      {
        path: 'device-change-lib-log',
        name: 'deviceChangeLibLog',
        component: DeviceChangeLibLog,
        meta: { title: '设备流转记录' }
      },
      {
        path: 'ota-helper',
        name: 'otaHelper',
        component: OTAHelper,
        meta: { title: '升级助手' }
      },
      {
        path: 'reader-device-auth-setting',
        name: 'readerDeviceAuthSetting',
        component: ReaderDeviceAuthSetting,
        meta: { title: '设备激活管理' }
      },
      {
        path: 'reader-device-auth-list',
        name: 'readerDeviceAuthList',
        component: ReaderDeviceAuthList,
        meta: { title: '激活设备列表' }
      }
    ]
  },
  {
    path: 'g-lib',
    component: RouterView,
    meta: {
      title: '机构开通',
      icon: 'el-icon-location-outline'
    },
    children: [
      { path: 'lib', name: 'lib', component: Lib, meta: { title: '服务开通' } },
      {
        path: 'lib-temp',
        name: 'libTemp',
        component: LibTemp,
        meta: { title: '行业模版' }
      }
    ]
  },
  {
    path: 'g-order',
    component: RouterView,
    meta: {
      title: '合同订单',
      icon: 'el-icon-goods'
    },
    children: [
      {
        path: 'order',
        name: 'order',
        component: Order,
        meta: { title: '订单中心' }
      }
    ]
  },
  {
    path: 'g-customer',
    component: RouterView,
    meta: {
      title: '用户',
      icon: 'el-icon-ant-user'
    },
    children: [
      {
        path: 'feedback',
        name: 'feedback',
        component: Feedback,
        meta: { title: '使用反馈' }
      }
    ]
  },
  {
    path: 'g-system',
    component: RouterView,
    meta: {
      title: '系统',
      icon: 'el-icon-setting'
    },
    children: [
      {
        path: 'admin-account',
        name: 'adminAccount',
        component: AdminAccount,
        meta: { title: '账号管理' }
      },
      {
        path: 'role',
        name: 'role',
        component: Role,
        meta: { title: '角色权限' }
      },
      {
        path: 'maintain_account',
        name: 'maintainAccount',
        component: MaintainAccount,
        meta: { title: '运维工具账号管理' }
      }
    ]
  },
  {
    path: '',
    component: RouterView,
    meta: {
      title: '应用',
      icon: 'el-icon-menu'
    },
    children: [
      {
        path: 'third-party-app',
        name: 'thirdPartyApp',
        component: ThirdPartyApp,
        meta: { title: '链接应用管理' }
      }
    ]
  }
]

export const getRouter = () => asyncRouterConfig

export const getMenuItems = (auth: boolean) => {
  const userMenus = _.get(AppStore.state.user.data, 'menues', [])
  return _.reduce(
    asyncRouterConfig,
    (result, route) => {
      const children = _.filter(route.children, child => {
        return auth ? _.includes(userMenus, child.path) : true
      })
      if (_.size(children)) {
        const group = {
          ...route.meta,
          key: route.path,
          children: _.map(children, childRoute => {
            return {
              ...childRoute.meta,
              key: childRoute.path,
              routerName: childRoute.name
            }
          })
        }
        result.push(group)
      }
      return result
    },
    []
  )
}
