const AppStore = {
  state: {
    user: {
      data: null
    },
    lib: {
      data: null
    },
    ui: {
      openNav: true,
      notification: {}
    }
  }
}

export default AppStore
